import '~/scripts/integrations/jquery-extended';

export default (function() {
  var hoverTimer;
  var valueText;
  var useType;

  /*
  *
  * Constructor
  *
  *
  * */
  var H = function() {
    this.addEvents();
    this.loadIntegrationCarousel();
    this.icons();

    $('.homepage-carousel').carousel({
      interval: 3700,
      ride: 'carousel',
      pause: false,
      keyboard: true,
      touch: true
    });

    makeLogos();
  };

  function makeLogos() {
    var $track = $('.logoTrack');
    $track.find(".logoCustomer").clone().appendTo($track);
    $(".lazy").lazy();
  }

  H.prototype.icons = function() {
    var html = '  <defs>\n' +
        '    <linearGradient id="halfStarSVGGradient" x1="0%" y1="0%" x2="100%" y2="0%">\n' +
        '      <stop offset="0%" stop-color="#f56f5b"></stop>\n' +
        '      <stop offset="60%" stop-color="#f56f5b"></stop>\n' +
        '      <stop offset="60%" stop-color="white"></stop>\n' +
        '      <stop offset="100%" stop-color="white"></stop>\n' +
        '    </linearGradient>\n' +
        '  </defs>';

    var $starRatings = $('#starRatings');

    var interval = setInterval(function() {
      if ($starRatings.find('svg').length) {
        clearInterval(interval);
        $starRatings.find('svg:not(#halfStarSVG)').attr('fill', '#f56f5b');

        // Here I'm targeting only the middle start by ID
        // You should modify the code to target all half-star
        $('#halfStarSVG').append(html);
        $('#halfStarSVG polygon').attr('fill', 'url(#halfStarSVGGradient)');
        $starRatings[0].innerHTML += "";
        $starRatings.removeClass("invisible");
      }
    }, 100);
  };

  H.prototype.loadIntegrationCarousel = function() {
    var carouselDefaults = {
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    var reviewCarouselSettings = carouselDefaults;
    reviewCarouselSettings.autoplay = true;
    reviewCarouselSettings.infinite = true;

    if ($.fn.slick) {
      $('#featureCarousel')
        .on('init', function (_event, _slick, _currentSlide, _nextSlide) {
          R.utils.loadLazyImages('#featureCarousel');
        }).slick(carouselDefaults);
    }
  };

  H.prototype.hoverIntegrations = function(el) {

  };

  H.prototype.addEvents = function() {
    var phoneCarouselSelector, phoneCarousel, videos, $videos;

    $("#accordion-features .accordion-item").on("show.bs.collapse", function() {
      var id = $(this).data('id');
      $("#accordion-images .accordion-image").hide();
      $("#"+id).fadeIn();
    });

    $('.accordion a, .slick-arrow, button').on(R.touchEvent, function() {
      $('.lazy').lazy();
    });



    phoneCarouselSelector = document.querySelector('#phone-carousel');
    phoneCarousel = new bootstrap.Carousel(phoneCarouselSelector, {
      ride: false,
      pause: false,
      keyboard: false,
      touch: false,
      slide: false
    });

    $videos = $(phoneCarouselSelector).find('video');

    try {
      $videos[0].play();
    } catch (err) {
      $videos.controls = true;
    }

    $videos.on('ended', function() {
      phoneCarousel.next('show');

      if ($(this).index()+1 === $videos.length) {
        $videos[0].currentTime = 0;
        phoneCarousel.to(0);
        $videos[0].play();
      }
    });
  };

  return H;
})();
