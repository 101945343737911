import Swal from 'sweetalert2';

const globalOpts = {};
const disableAnimationOpts = {
  // animation: false, // for when we upgrade to v11 of Swal
  showClass: {
    backdrop: 'swal2-noanimation', // disable backdrop animation
    popup: '',                     // disable popup animation
    icon: ''                       // disable icon animation
  },
  hideClass: {
    popup: '',                     // disable popup fade-out animation
  },
};

if(gon.railsEnv == "test") {
  $.extend(globalOpts, disableAnimationOpts);
}

window.Swal = Swal.mixin(globalOpts);
