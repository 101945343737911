import '~/scripts/integrations/jquery-extended';
import Home from './home';

export default class extends Home {
  constructor() {
    super(...arguments);
    this.loadSlick();
  }

  loadSlick() {
    const carouselDefaults = {
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ],
      autoplay: true,
      infinite: true
    };

    if ($.fn.slick) {
      $('#featureCarousel').slick(carouselDefaults);
      $('#customerReviewCarousel').slick(carouselDefaults);
    } else {
      setTimeout(() => {
        this.loadSlick();
      }, 800);
    }
  }
}
