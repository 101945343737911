export default class ScrollMenu {
  constructor(menu, navItem, _, callback) {
    this.menu = menu || "#article-menu";
    this.$content = $(navItem);
    this.$menu = $(this.menu);
    this.setupVariables(() => {
      this.setNavHighlighting();
      this.addEvents();
      if (callback) callback();
    });
  }

  setupVariables(callback) {
    let $images = $("img");
    let imagesToLoad = [];
    let interval;
    let defaultMenuPos;

    // Image management
    if ($images.length) {
      $images.each(function() {
        // Image not loaded?
        if (!this.complete) {
          imagesToLoad.push(this);
        }
      });

      if (imagesToLoad.length) {
        $images.on('load', () => {
          defaultMenuPos = parseInt(this.$menu.offset().top.valueOf());
        });
      } else {
        defaultMenuPos = parseInt(this.$menu.offset().top.valueOf());
      }
    }

    interval = setInterval(() => {
      if (defaultMenuPos !== undefined) {
        clearInterval(interval);

        // To make a copy of the menuPosition so it doesn't change when the $menu.offset().top changes.
        $.extend(this, { defaultMenuPosition: defaultMenuPos });

        callback();
      }
    }, 200);
  }

  setNavHighlighting() {
    if (!this.$menu) return;

    let scrollTop = $window.scrollTop();

    this.$content.each(function(i, element) {
      let $this = $(element);
      console.log($.ui.version);
      let offsetTop = $this.offset().top;

      if (offsetTop <= (scrollTop + 180)) {
      // if (offsetTop <= (scrollTop+200) && scrollTop < (nextItemTop+200)) {
        this.$menu.find("a").removeClass("active");
        this.$menu.find("a[href='#" + element.id + "']").addClass("active");
      }
    }.bind(this));
  }

  addEvents() {
    let highlightTimer;

    if (this.$menu.length) {
      $window.on('scroll.article', () => {
        highlightTimer = setTimeout(() => {
          clearTimeout(highlightTimer);
          this.setNavHighlighting();
        }, 200);
      });
    }

    $document.on('animate-scroll', this.setNavHighlighting.bind(this));
    $document.one("turbo:before-fetch-request", this.removeEvents.bind(this));
  }

  removeEvents() {
    $window.off('scroll.article');
    $document.off('animate-scroll', this.setNavHighlighting.bind(this));
  }
}

