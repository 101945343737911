import '~/scripts/integrations/jquery-extended';

R.ui.passwordToggle = (function() {

  return function($parent, target) {
    target = target || '#user_password';
    var $targetElement = $(target);

    var $input = $parent.find(".form-check-input");
    $input.on('change', function() {
      var type = "text";
      var checkedAttribute = $(this).prop("checked");
      if (!checkedAttribute) {
        type = "password";
      }

      $targetElement.prop("type", type);
    });
  };

})();
