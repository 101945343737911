import '~/scripts/integrations/jquery-extended';
import zxcvbn from 'zxcvbn';

/**
 * JS integration for password input utils
 *
 * main public methods:
 *   bindAll() - bind password meter, pw toggle & icon visibility toggle
 *   bindInputWithPasswordMeter() - bind password meter only
 */
window.R.ui.PasswordUtils = (function() {
  var PU = function (){};

  PU.prototype.bindAll = function (toggleMeter) {
    this.bindInputWithPasswordMeter(toggleMeter);
    this.bindTogglePasswordVisibility();
  };

  PU.prototype.bindTogglePasswordVisibility = function ($wrapper) {
    $wrapper = $wrapper || $('.password-toggle').parent();
    R.ui.passwordToggle($wrapper);
  };

  PU.prototype.bindInputWithPasswordMeter = function (toggleMeter, callback) {
    var that = this;
    var inputSelector = '#user_password';
    $(inputSelector).on('input', function () {
      var newPass = $(this).val();
      updatePasswordStrengthMeter(newPass, toggleMeter);

      if (callback){
        callback(newPass);
      }
    });
  };

  // @param toggleMeter: whether to hide meter when password is empty
  //                     (does not cover default case - that should be handled by CSS)
  function updatePasswordStrengthMeter(newPass, toggleMeter) {
    if (typeof zxcvbn === "undefined")
      return;

    var $pwStrengthSection = $('.password-strength');
    var $strengthLabelWrapper = $pwStrengthSection.find('.strength-label-wrapper');
    var $labels = $strengthLabelWrapper.find('.labels').children();
    $labels.hide(); // clear previous label
    var $meter = $pwStrengthSection.find('#password-meter');

    var resetMeterAndLabel = function() {
      $meter.val(0);
      $labels.filter('.default').show();
      if (toggleMeter) {
        $pwStrengthSection.hide();
      }
    };

    if (newPass) {
      // Note: this can be made async if needed, which might improve the UX on slow systems (esp. for longer passwords)
      var score = zxcvbn(newPass).score;
      if(score || score === 0) {
        $meter.val(score);
        var $relevantLabel = $labels.filter('[data-score="' + score + '"]');
        if ($relevantLabel.length){
          $strengthLabelWrapper.show();
          $relevantLabel.show();
        } else { // unusual case
          $strengthLabelWrapper.hide();
        }
        $pwStrengthSection.show();
        // tooltip for the password input might continue to be shown when typing the password, which overlays the strength meter
        $('.tooltip').remove();
      } else { // unusual case
        resetMeterAndLabel();
      }
    } else {
      resetMeterAndLabel();
    }
  }

  return PU;
})();
