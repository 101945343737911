import '~/scripts/integrations/jquery-extended';

export default (function() {
  var Teams = function() {
    this.topCarousel();
  };

  Teams.prototype.topCarousel = function() {
    var carouselDefaults = {
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      infinite: true,
      speed: 1000,
      autoplaySpeed: 5000,
      dots: true,
      arrows: false
    };

    if ($.fn.slick) {
      $('#topCarousel').slick(carouselDefaults);
    }
  };

  return Teams;
})();
